<template>
    <vs-popup class="essaipro_popup" title="Provenance séances" :active.sync="popupActive">
        
        <div class="vx-col w-full">
            <vs-input class="w-full" label="Nom" v-model="channel.name" type="text" maxlength="15"/>
        </div>
        <div class="vx-col w-full">
            <vs-input class="w-full" label="Couleur" v-model="channel.color" type="color"/>
        </div>

        <vs-divider/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <template v-if="!uid">
                    <div></div>
                </template>
                <template v-else>
                    <vs-button color="danger" type="filled" v-on:click="del()">Supprimer</vs-button>
                </template>

                <vs-button color="success" type="filled" v-on:click="add()">
                    <template v-if="!uid">
                        Ajouter
                    </template>
                    <template v-else>
                        Modifier
                    </template>
                </vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>
</template>

<style lang="scss">
.essaipro_popup{
    input[type$="color"]{
        height:50px !important;
    }
}
</style>

<script>

import Channel from '@/database/models/channel';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            channel:{
                name:'',
                color:'#000000',
            },
            uid: undefined,
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
        this.reset()
    },
    methods:{
        openPopup( data, callback )
        {
            this.reset()
            this.callback    = null
            this.popupActive = true
            this.uid         = undefined

            //si envoi de data
            if(data)
            {
                if(data.uid)
                    this.uid = data.uid

                this.channel =  JSON.parse(JSON.stringify(data))
                this.channel['uid'] = undefined
            }

            if(callback)
                this.callback = callback
        },
        add()
        {
            if(this.channel.name.length < 1 )
                return alert('Nom vide !');

            if(this.uid)
            {
                //Ajout Channel
                Channel.update(this.uid, this.channel )
                .then(()=>{
                    //callback event
                    if(this.callback)
                        this.callback(this.uid)
                    //Event refresh
                    this.$emit('refresh')
                })
            }
            else
            {
                //Ajout Channel
                Channel.add(this.channel)
                .then((uid)=>{
                    //callback event
                    if(this.callback)
                        this.callback(uid)
                    //Event refresh
                    this.$emit('refresh')
                })
            }

            //fermeture popup
            this.popupActive = false
        },

        del()
        {
            //supprimer Channel
            Channel.remove(this.uid)
            .then(()=>{
                //callback event
                if(this.callback)
                    this.callback(this.uid)
                //Event refresh
                this.$emit('refresh')
            })
            //fermeture popup
            this.popupActive = false
        },

        reset()
        {
            this.channel.name  = ''
            this.channel.color = '#000000'

            this.uid           = undefined
            this.callback      = null
            this.popupActive   = false
        },
    }
}

</script>